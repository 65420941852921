import { template as template_fab8b1a863e9400da3013d4763213e75 } from "@ember/template-compiler";
const FKText = template_fab8b1a863e9400da3013d4763213e75(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
