import { template as template_2d073503da3b4dda8a7515c42c181426 } from "@ember/template-compiler";
const EmptyState = template_2d073503da3b4dda8a7515c42c181426(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
