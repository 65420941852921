import { template as template_15e707032ffc4c9b9cce817c1c16b6a0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_15e707032ffc4c9b9cce817c1c16b6a0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
