import { template as template_1a5a62de12164f6395ad508240e0866d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_1a5a62de12164f6395ad508240e0866d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
