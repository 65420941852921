import { template as template_966c6595feef45faae21b26e6cb94c61 } from "@ember/template-compiler";
const WelcomeHeader = template_966c6595feef45faae21b26e6cb94c61(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
